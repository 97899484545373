<template>
    <section class="mt-4">
      <b-tabs>
        <b-tab-item label="Таблица">
          <div class="table-container">
            <b-table
              :data="data"
              :bordered="true"
              :narrowed="true"
              :hoverable="true"
              detailed
              default-sort-direction="desc"
              default-sort="date">

              <b-table-column field="pid" label="PID" width="40" numeric centered v-slot="props">
                {{ props.row.pid }}
              </b-table-column>

              <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="code" label="CODE" width="40" numeric centered v-slot="props">
                {{ props.row.code }}
              </b-table-column>

              <b-table-column field="active" label="Активно" numeric centered v-slot="props">
                <template v-if="props.row.active == 0">
                  <b-tooltip label="Нет">
                    <font-awesome-icon icon="times" />
                  </b-tooltip>
                </template>
                <template v-else="">
                  <b-tooltip label="Да">
                    <font-awesome-icon icon="check" />
                  </b-tooltip>
                </template>
              </b-table-column>

              <b-table-column field="length" label="Количество модификаторов" width="40" numeric centered v-slot="props">
                {{ getLength(props.row.groups) }}
              </b-table-column>

              <b-table-column field="action" label="Действие" centered v-slot="props">
                <b-tooltip label="Просмотр">
                  <router-link :to="{name: 'a-modifier', params: {id: props.row.id }}" class="navbar-item">
                    <font-awesome-icon icon="eye" />
                  </router-link>
                </b-tooltip>
              </b-table-column>

              <template slot="detail" slot-scope="props">
                <b-table
                  :data="props.row.groups"
                  :bordered="true"
                  :narrowed="true"
                  :hoverable="true"
                  detailed
                  default-sort-direction="desc"
                  default-sort="date">

                  <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                    {{ props.row.id }}
                  </b-table-column>

                  <b-table-column field="name" label="Наименование" width="40" numeric centered v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>

                  <b-table-column field="active" label="Активно" numeric centered v-slot="props">
                    <template v-if="props.row.active == 0">
                      <b-tooltip label="Нет">
                        <font-awesome-icon icon="times" />
                      </b-tooltip>
                    </template>
                    <template v-else="">
                      <b-tooltip label="Да">
                        <font-awesome-icon icon="check" />
                      </b-tooltip>
                    </template>
                  </b-table-column>

                  <b-table-column field="name" label="Признак модификатора по-умолчанию" width="40" numeric centered v-slot="props">
                    <!-- {{ props.row.defaultModifier }} -->
                    <template v-if="props.row.defaultModifier == 0">
                      <b-tooltip label="Нет">
                        <font-awesome-icon icon="times" />
                      </b-tooltip>
                    </template>
                    <template v-else="">
                      <b-tooltip label="Да">
                        <font-awesome-icon icon="check" />
                      </b-tooltip>
                    </template>
                  </b-table-column>

                  <b-table-column field="name" label="Замена модификатора по-умолчанию" width="40" numeric centered v-slot="props">
                    <!-- {{ props.row.replaceDefModifier }} -->
                    <template v-if="props.row.replaceDefModifier == 0">
                      <b-tooltip label="Нет">
                        <font-awesome-icon icon="times" />
                      </b-tooltip>
                    </template>
                    <template v-else="">
                      <b-tooltip label="Да">
                        <font-awesome-icon icon="check" />
                      </b-tooltip>
                    </template>
                  </b-table-column>

                  <b-table-column field="useDownLimit" label="Признак применения ограничения снизу (по количеству)" width="40" numeric centered v-slot="props">
                    {{ props.row.useDownLimit }}
                  </b-table-column>

                  <b-table-column field="downLimit" label="Минимальное количество модификатора для блюда" width="40" numeric centered v-slot="props">
                    {{ props.row.downLimit }}
                  </b-table-column>

                  <b-table-column field="useUpLimit" label="Признак применения ограничения сверху (по количеству)" width="40" numeric centered v-slot="props">
                    {{ props.row.useUpLimit }}
                  </b-table-column>

                  <b-table-column field="upLimit" label="Максимальное количество модификатора для блюда" width="40" numeric centered v-slot="props">
                    {{ props.row.upLimit }}
                  </b-table-column>

                  <template slot="detail" slot-scope="props">
                    <b-table
                      :data="props.row.modi"
                      :bordered="true"
                      :narrowed="true"
                      :hoverable="true"
                      default-sort-direction="desc"
                      default-sort="date">

                      <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                        {{ props.row.id }}
                      </b-table-column>

                      <b-table-column field="name" label="Наименование" width="40" numeric centered v-slot="props">
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column field="active" label="Активно" numeric centered v-slot="props">
                        <template v-if="props.row.active == 0">
                          <b-tooltip label="Нет">
                            <font-awesome-icon icon="times" />
                          </b-tooltip>
                        </template>
                        <template v-else="">
                          <b-tooltip label="Да">
                            <font-awesome-icon icon="check" />
                          </b-tooltip>
                        </template>
                      </b-table-column>

                      <b-table-column field="maxOneDish" label="Максимальное количество на одно блюдо" width="40" numeric centered v-slot="props">
                        {{ props.row.maxOneDish }}
                      </b-table-column>

                      <b-table-column field="price" label="Цена" width="40" numeric centered v-slot="props">
                        {{ props.row.price/100 }}
                      </b-table-column>

                      <b-table-column field="price" label="Вес" width="40" numeric centered v-slot="props">
                        {{ props.row.weight/100 }}
                      </b-table-column>

                    </b-table>
                  </template>

                </b-table>

              </template>

            </b-table>
          </div>
        </b-tab-item>
        <b-tab-item label="JSON">
            <div class="app-json">
              <vue-json-pretty
                :path="'res'"
                :data="data">
              </vue-json-pretty>
            </div>
          </b-tab-item>
        </b-tabs>
    </section>
</template>


<script>
import { EventBus } from '@/utils'
import { getModifier } from '@/api/modifier'
// import { ToastProgrammatic as Toast } from 'buefy'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: "ModifiersForm",
  components: {
    VueJsonPretty
  },
  data() {
    const data = []

    return {
      data: data,
      mod_id: this.$route.params.id,
    }
  },
  computed: {
    //
  },
  mounted: function() {
    let $this = this

    getModifier(this.mod_id, this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        $this.total = data.total

        data.data.forEach((item) => {
          this.data.push(item)
        })
      })
      .catch(
        this.data = []
      )

    // EventBus.$on('error', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.error')} ${error}`,
    //     message: 'error',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

    // EventBus.$on('fail', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.fail')} ${error}`,
    //     message: 'fail',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

  },
  beforeDestroy: function(){
    EventBus.$off('error')
    EventBus.$off('fail')
  },
  methods: {
    getLength(value){
      if (value.length > 0){
        let length = 0

        value.forEach((item) => {
          // length = length + 1

          if (item.modi.length > 0){
            item.modi.forEach(() => {
              length = length + 1
            })
          } 
        })

        return value.length
      }
      else{
        return 0
      }
    }
  }
}
</script>
<style>
</style>